import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Utente } from '../../models/Utente';
import ApiUtentiAdmin from '../../api/UtentiAdmin';
import ApiInfermieri from '../../api/Infermieri';
import LoadingView from '../../components/generics/LoadingView';
import '../../style/stylePages/admin/UtentiInfoAdmin.css';
import { Button } from '@mui/material';
import { InfermiereInfo } from '../../models/InfermiereInfo';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";


function UtenteInfoAdmin() {
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState<String>();
    const [utente, setUtente] = useState<Utente>();
    const [usr, setUsr] = useState<any>();

    const [infermiere, setInfermiere] = useState<InfermiereInfo>();

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search);

    const navigate = useNavigate();


    const getUtente = async (idUtente: string | null) => {
        if(idUtente !== null) {
            setLoading(true);
            const pren: Utente = await ApiUtentiAdmin.getSingoloUtente(idUtente);
            setUtente(pren);
            if(pren.ruolo === "infermiere") {
                const infInfo: InfermiereInfo = await ApiInfermieri.getSingoloInfermiere(idUtente);
                setInfermiere(infInfo);
            }
            setLoading(false);
        }
    }

    const autorizza = async () => {
        if(utente && utente._id) {
            try {
                setLoading(true);
                await ApiUtentiAdmin.autorizzaVisibilitaInfermiere(utente.email!);
                setLoading(false);
                setUtente(undefined);
                toast.success("Infermiere autorizzato!");
            } catch (err: any) {
                setLoading(false);
                console.log(err);
                toast.error("Impossibile autorizzare l'infermiere!");
            }
            
        }
    }

    const rimuoviAutorizzazione = async () => {
        if(utente && utente._id) {
            try {
                setLoading(true);
                await ApiUtentiAdmin.rimuoviVisibilitaInfermiere(utente.email!);
                setLoading(false);
                setUtente(undefined);
                toast.success("Autorizzazione infermiere revocata!");
            } catch (err: any) {
                setLoading(false);
                console.log(err);
                toast.error("Impossibile revocare l'autorizzazione infermiere!");
            }
            
        }
    }

    const abilita = async () => {
        if(utente && utente._id) {
            try {
                setLoading(true);
                await ApiUtentiAdmin.autorizzaAccessoInfermiere(utente.email!);
                setLoading(false);
                setUtente(undefined);
                toast.success("Infermiere abilitato!");
            } catch (err: any) {
                setLoading(false);
                console.log(err);
                toast.error("Impossibile autorizzare l'infermiere!");
            }
            
        }
    }

    const disabilita = async () => {
        if(utente && utente._id) {
            try {
                setLoading(true);
                await ApiUtentiAdmin.rimuoviAccessoInfermiere(utente.email!);
                setLoading(false);
                setUtente(undefined);
                toast.success("Infermiere disabilitato!");
            } catch (err: any) {
                setLoading(false);
                console.log(err);
                toast.error("Impossibile revocare l'autorizzazione infermiere!");
            }
            
        }
    }


    useEffect(() => {
        const id = queryParameters.get("id");
        const t = queryParameters.get("t");
        if(t) 
            setTab(t);

        if(!usr) {
            let authCookie = Cookies.get('auth');
            let user = {}
            if(authCookie) {
                let ck = JSON.parse(authCookie);
                user = ck.user;
                setUsr(user);
            }
        }
        if(usr) {
            if(usr.ruolo != "admin") {
                navigate('/');
            }
        }

        if(!utente) {
            getUtente(id);
        }
    })

    return(
        <div className="page-template">
            <div className='main-content utente-info'>
                <h2>Info Utente</h2>
                <div className='button-container'>
                    <a onClick={() => navigate(`/utenti`)}>Indietro</a>
                </div>
                <div className='button-row-container' style={{marginTop: 20}}>
                    <Button variant="contained" onClick={() => navigate(utente ? `/calendario?id=${utente._id}` : '#')}>Calendario</Button>
                    <Button variant="contained" onClick={() => navigate(utente ? `/servizi?id=${utente._id}` : '#')}>Servizi</Button>
                    <Button variant="contained" onClick={() => navigate(utente ? `/?id=${utente._id}` : '#')}>Modifica profilo</Button>
                </div>
                <div className='flex-container'>
                    <div>
                        <div className='flex-container-row'><b>Id Utente: </b><p>{utente?._id}</p></div>
                        <div className='flex-container-row'><b>Email: </b><p>{utente?.email}</p></div>
                        <div className='flex-container-row'><b>Nome: </b><p>{utente?.nome}</p></div>
                        <div className='flex-container-row'><b>Cognome: </b><p>{utente?.cognome}</p></div>
                        <div className='flex-container-row'>
                            <b>Verificato: </b>
                            <p className={utente?.verificatoUtente ? 'green-label' : 'red-label'}>{utente?.verificatoUtente ? "Verificato" : "Non verificato"}</p>
                        </div>
                        <div className='flex-container-row'>
                            <b>Attivo (accesso consentito): </b>
                            <p className={utente?.autorizzatoInfermiere ? 'green-label' : 'red-label'}>{utente?.autorizzatoInfermiere ? "Attivo" : "Non attivo"}</p>
                        </div>
                        <div className='flex-container-row'>
                            <b>Autorizzato (visibile a frontend): </b>
                            <p className={utente?.visibileInfermiere ? 'green-label' : 'red-label'}>{utente?.visibileInfermiere ? "Autorizzato" : "Non autorizzato"}</p>
                        </div>
                    </div>
                    {utente?.ruolo === "infermiere" && <div>
                        <div className='flex-container-row'><b>Indirizzo studio: </b><p>{infermiere?.indirizzo}</p></div>
                        <div className='flex-container-row'><b>Raggio d'azione: </b><p>{infermiere?.distanza}</p></div>
                        <div className='flex-container-row'><b>Telefono: </b><p>{infermiere?.telefono}</p></div>
                        <div className='flex-container-row'><b>Struttura: </b><p>{infermiere?.idStruttura}</p></div>
                        <div className='flex-container-row'><b>Descrizione: </b><p>{infermiere?.descrizione}</p></div>
                    </div>}
                </div>
                {utente?.ruolo === "infermiere" && <h3>Dati fatturazione</h3>}
                {<div className='flex-container'>
                    <div>
                        <div className='flex-container-row'><b>Nome / Ragione sociale: </b><p>{infermiere?.datiFatturazione?.nome}</p></div>
                        <div className='flex-container-row'><b>CAP: </b><p>{infermiere?.datiFatturazione?.cap}</p></div>
                        <div className='flex-container-row'><b>Provincia: </b><p>{infermiere?.datiFatturazione?.provincia}</p></div>
                        <div className='flex-container-row'><b>Partita IVA: </b><p>{infermiere?.datiFatturazione?.partitaIva}</p></div>
                    </div>
                    <div>
                        <div className='flex-container-row'><b>Indirizzo: </b><p>{infermiere?.datiFatturazione?.indirizzo}</p></div>
                        <div className='flex-container-row'><b>Città: </b><p>{infermiere?.datiFatturazione?.citta}</p></div>
                        <div className='flex-container-row'><b>Codice fiscale: </b><p>{infermiere?.datiFatturazione?.codiceFiscale}</p></div>
                        <div className='flex-container-row'><b>SDI/PEC: </b><p>{infermiere?.datiFatturazione?.sdi}</p></div>
                    </div>
                </div>}
                <div className='button-container'>
                    {utente && !utente.verificatoUtente && <p className='not-verified'>Non verificato</p>}

                    {utente && utente.verificatoUtente && !utente.autorizzatoInfermiere && <Button variant="contained" onClick={abilita}>Attiva (permetti accesso)</Button>}
                    {utente && utente.verificatoUtente && utente.autorizzatoInfermiere && <Button variant="contained" color="error" 
                        onClick={disabilita}>Disattiva (revoca accesso)</Button>}

                    {utente && utente.verificatoUtente && utente.autorizzatoInfermiere && !utente.visibileInfermiere && <Button variant="contained" onClick={autorizza}>Autorizza (mostra a frontend)</Button>}
                    {utente && utente.verificatoUtente && utente.autorizzatoInfermiere && utente.visibileInfermiere && <Button variant="contained" color="error" 
                        onClick={rimuoviAutorizzazione}>Revoca autorizzazione (nascondi a frontend)</Button>}
                    
                    

                </div>
            </div>   
            <LoadingView
                visible={loading}
            />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default UtenteInfoAdmin;