import { useEffect, useState } from "react";
import { Utente } from "../../models/Utente";
import { Button, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import ApiServizi from '../../api/Servizi';
import Cookies from "js-cookie";
import { cloneDeep, set } from "lodash";
import GiornoCalendarioUtils from "../../utils/GiornoCalendarioUtils";
import ButtonMenu from "../../components/generics/ButtonMenu";
import '../../style/stylePages/admin/UtentiAdmin.css';
import { ToastContainer, toast } from "react-toastify";
import LavoraDate from "../../utils/LavoraDate";
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Servizio } from "../../models/Servizio";
import DialogServizioAdmin from "../../components/admin/DialogServizioAdmin";



function ServiziAdmin() {
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);    //Popup di modifica/aggiunta
    const [servizio, setServizio] = useState<any>();
    const [servizi, setServizi] = useState<Servizio[]>();
    const [servizioSelezionato, setServizioSelezionato] = useState<Servizio>();
    const [filtri, setFiltri] = useState({nome: undefined});
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(1);
    const [utente, setUtente] = useState<any>();

    const location = useLocation()
    const navigate = useNavigate();

    const algoliaReindex = async () => {
        try {
            await ApiServizi.algoliaReindex();
            toast.success("Algolia reindex eseguito con successo");
        } catch (err: any) {
            toast.error(err);
        }
    }

    const getServizi = async (page: number, limit: number = 10) => {
        const data = await ApiServizi.getElencoServizi(filtri, page, limit);
        if(data) {
            setServizi(data.docs);
            setTotalPages(data.totalPages);

        }
    }

    const deleteServizio = async (servizio: Servizio) => {
        try {
            //const data = await ApiInfermieriServizi.deleteServizioInfermiere(servizio);
            setServizioSelezionato(undefined);
            setServizi(undefined);
            toast.success("Servizio cancellato con successo");
        } catch (err: any) {
            toast.error(err);
        }
        
    }

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const f = cloneDeep(filtri);
        set(f, field, event.target.value);
        setFiltri(f);
        setServizi(undefined);
    };

    const openDialogFunc = (servizio?: Servizio) => {
        if(servizio)
            setServizioSelezionato(servizio);
        
        setOpenDialog(true);
    }

    const closeDialogAndRefresh = () => {
        setServizi(undefined);
        setServizioSelezionato(undefined);
        setOpenDialog(false);
    }

    const closeDialog = () => {
        setServizioSelezionato(undefined);
        setOpenDialog(false);
    }

    //Paginazione
    const handleChangePage = async (event: any, newPage: any) => {
        setPage(newPage);
        getServizi(newPage);
    }

    useEffect(() => {
        if(!utente) {
            let authCookie = Cookies.get('auth');
            let user = {}
            if(authCookie) {
                let ck = JSON.parse(authCookie);
                user = ck.user;
                setUtente(user);
            }
        }
        if(utente) {
            if(utente.ruolo != "admin") {
                navigate('/servizi');
            }
        }

        if(!servizi) {
            getServizi(page);
        }
    });
    
    return (
        <div className="page-template">
            <div className='main-content'>
                <h2>Servizi</h2>
                <div className="top-buttons">
                    <Button variant="contained" onClick={() => openDialogFunc()}>Aggiungi servizio</Button>
                    {/*<Button variant="contained" onClick={() => algoliaReindex()}>Algolia Reindex</Button>*/}
                </div>
                <div className="filtri" style={{marginTop: 50}}>
                    <h3 style={{marginTop: 13}}>Filtri: </h3>
                    <div className="filtro-box">
                        <TextField
                            value={filtri.nome || ''}
                            onChange={handleChange('nome')}
                            label="Nome Servizio" 
                            variant="outlined"
                            type="search"
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} className="tabella-servizi" aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Tipologia</TableCell>
                            <TableCell>Prezzo Minimo</TableCell>
                            <TableCell>Prezzo Massimo</TableCell>
                            <TableCell>Stato</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {servizi?.map((row: Servizio) => (
                            <TableRow
                                hover
                                key={row._id}
                                className="table-row"
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell onClick={() => openDialogFunc(row)}>{row._id}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)}>{row.nome}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)}>{row.tipologia ? "In Studio" : "A domicilio"}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)}>{row.prezzoMin}</TableCell>
                                <TableCell onClick={() => openDialogFunc(row)}>{row.prezzoMax}</TableCell>

                                <TableCell onClick={() => openDialogFunc(row)} className={row.attivo ? "green-label" : "red-label"}>
                                    {row.attivo ? "Attivo" : "Non attivo"}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack spacing={2} className='paginazione'>
                    <Pagination count={totalPages} color="primary" page={page} onChange={handleChangePage}/>
                </Stack>
            </div>
            <DialogServizioAdmin open={openDialog} onClose={closeDialog} onSave={closeDialogAndRefresh} servizio={servizioSelezionato} />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>

    );
}

export default ServiziAdmin;